import React from "react";
import { ArrowRight, MessagesSquare } from "lucide-react";
import { Conversation } from "../../clients/sol";

interface ConversationListProps {
  conversations: Conversation[];
  currentConversationId: string | null;
  handleConversationSelect: (id: string) => void;
  setIsSearchModalVisible: (show: boolean) => void;
}

export const ConversationList: React.FC<ConversationListProps> = ({
  conversations,
  currentConversationId,
  handleConversationSelect,
  setIsSearchModalVisible,
}) => {
  const displayedConversations = conversations.slice(0, 5);

  return (
    <>
      <div className="px-4 py-2 border-b flex items-center">
        <h3 className="text-sm font-bold">Recent Conversations</h3>
      </div>
      <div className="flex-1 overflow-y-auto">
        <div className="px-2 pb-2 flex flex-col gap-1">
          {displayedConversations.map((conv) => (
            <div
              key={conv.id}
              onClick={() => handleConversationSelect(conv.id)}
              className={`w-full flex items-center gap-2 px-2 py-2 rounded-lg text-sm transition-colors border-none cursor-pointer ${currentConversationId === conv.id
                ? "bg-neutral-200/50"
                : "hover:bg-neutral-200/50"
                }`}
            >
              <div>
                <MessagesSquare className="size-4" />
              </div>
              <div className="font-medium truncate">
                {conv.name || `New Chat`}
              </div>
            </div>
          ))}
          {conversations.length > 5 && (
            <div
              onClick={() => setIsSearchModalVisible(true)}
              className="w-full text-sm p-2 gap-1 flex items-center text-neutral-600 hover:text-neutral-800 cursor-pointer"
            >
              <span>View all</span>
              <ArrowRight className="size-3" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
