import { Modal } from "antd";
import { Search } from "lucide-react";
import { useState } from "react";
import { Conversation } from "../../../clients/sol";

export const SearchModal: any = ({
  conversations = [],
  open,
  close,
  onSelectConversation,
}: {
  conversations: Conversation[];
  open: boolean;
  close: () => void;
  onSelectConversation: (conversation: any) => void;
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  // Filter conversations based on search term
  const filteredConversations = conversations.filter((conv: any) =>
    (conv.name || `New Chat (${conv.id})`)
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  return (
    <Modal
      title={null}
      open={open}
      onCancel={close}
      footer={null}
      width={600}
      className="rounded-xl"
      style={{
        borderRadius: "1rem",
      }}
    >
      <div className="flex flex-col gap-4">
        <div className="text-lg font-semibold">Search Conversations</div>
        {/* Search Input */}
        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
          <input
            type="text"
            className="
                w-full pl-10 pr-4 py-2.5
                border border-gray-200
                rounded-lg
                bg-white
                text-gray-900
                placeholder-gray-500
                focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent
                transition duration-200
              "
            style={{ border: "1px solid #d1d5db" }}
            placeholder="Search conversations..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            autoFocus
          />
        </div>

        {/* Conversation List */}
        <div className="max-h-96 overflow-y-auto">
          <div className="flex flex-col gap-2">
            {filteredConversations.length > 0 ? (
              filteredConversations.map((conversation: any) => (
                <div
                  key={conversation?.id}
                  onClick={() => {
                    onSelectConversation(conversation);
                    close();
                    setSearchTerm("");
                  }}
                  className="
                      w-full p-3
                      bg-white
                      rounded-lg
                      text-neutral-300
                      text-left
                      hover:bg-gray-50
                      focus:outline-none focus:ring-2 focus:ring-blue-500 focus:bg-gray-50
                      group
                      cursor-pointer  
                    "
                  style={{
                    border: `1px solid rgb(212 212 212)`,
                  }}
                >
                  <div className="flex items-center justify-between">
                    <span className="text-sm text-gray-900 font-medium truncate">
                      {conversation?.name ?? `New Chat (${conversation?.id})`}
                    </span>
                    <svg
                      className="w-4 h-4 text-gray-400 group-hover:text-gray-600 transition-colors"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center py-8 text-gray-500">
                No conversations found
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};