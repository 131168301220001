import React, { useEffect, useRef, useState } from "react";
import { ArrowUp, Settings2, Square } from "lucide-react";
import { ChatCalendar } from "./ChatCalendar";
import { ModelId, supportedModels } from "../config";
import { Dropdown, MenuProps } from "antd";

interface TextBoxProps {
  isLoading: boolean;
  onSubmit: (message: string) => void;
  stopGeneration?: () => void;
  startDate?: Date;
  setStartDate?: (date: Date) => void;
  endDate?: Date;
  setEndDate?: (date: Date) => void;
  updateConversationDateRange?: (start: Date, end: Date) => void;
  model: ModelId;
  setModel: (model: ModelId) => void;
}

export const TextBox = ({
  isLoading,
  onSubmit,
  stopGeneration,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  updateConversationDateRange,
  model,
  setModel,
}: TextBoxProps) => {
  const [input, setInput] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [textareaHeight, setTextareaHeight] = useState(24);

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    textarea.style.height = "auto";
    const newHeight = Math.min(textarea.scrollHeight, 150);
    textarea.style.height = `${newHeight}px`;
    setTextareaHeight(newHeight);
  };

  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
    adjustHeight();
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (isLoading) {
      stopGeneration?.();
    } else {
      if (!input.trim()) return;
      onSubmit(input);
      setInput("");
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
        setTextareaHeight(24);
      }
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [input]);

  const modelSelectMenuItems: MenuProps["items"] = Object.values(
    supportedModels
  ).map((m) => ({
    key: m.id,
    label: m.name,
    onClick: () => setModel(m.id),
  }));

  return (
    <form onSubmit={handleSubmit} className="p-2 pt-0">
      <div
        className="rounded-xl bg-neutral-50/60"
        style={{ border: "1px solid #d1d5db" }}
        onClick={() => textareaRef.current?.focus()}
      >
        <div className="relative">
          <div
            className="absolute bottom-0 left-0 right-0 h-10 pointer-events-none"
            style={{
              background: "linear-gradient(to bottom, transparent, white)",
              transform: `translateY(${-textareaHeight - 39}px)`,
            }}
          />
          <div className="px-4 py-4">
            <textarea
              ref={textareaRef}
              value={input}
              onChange={handleInput}
              disabled={isLoading}
              placeholder="Type your message..."
              rows={1}
              className="w-full rounded-sm bg-transparent border-none focus:ring-0 focus:outline-none placeholder:text-neutral-800 resize-none overflow-y-auto"
              style={{
                minHeight: "24px",
                maxHeight: "150px",
              }}
              onKeyDown={(e) => {
                // if it's enter let's submit the form
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handleSubmit(e);
                }

                // if shift key then create a new line
                if (e.key === "Enter" && e.shiftKey) {
                  setInput((prev) => `${prev}\n`);
                }
              }}
            />
          </div>
        </div>
        <div className="border-t border-neutral-200 px-4 pb-3 flex items-center justify-between">
          <div className="flex items-center text-neutral-600">
            {/* <Plus className="size-5 cursor-pointer" /> */}
            <ChatCalendar
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              updateConversationDateRange={updateConversationDateRange}
            />
            <Dropdown
              trigger={["click"]}
              menu={{ items: modelSelectMenuItems }}
            >
              <div className="bg-neutral-100 px-3 py-2 rounded-xl flex items-center gap-1 text-xs font-medium cursor-pointer">
                <Settings2 className="size-4" />
                <div>{supportedModels[model].name}</div>
              </div>
            </Dropdown>
          </div>
          <div className="flex items-center gap-3">
            {isLoading && (
              <button
                type="submit"
                className="cursor-pointer bg-gray-700 text-white flex items-center justify-center rounded-full hover:bg-gray-500 transition-colors disabled:bg-gray-400 outline-none focus:ring-0 border-none size-8"
              >
                <Square className="size-4" strokeWidth="3" />
              </button>
            )}
            {!isLoading && (
              <button
                type="submit"
                className="cursor-pointer bg-blue-600 text-white flex items-center justify-center rounded-full hover:bg-blue-700 transition-colors disabled:bg-blue-300 outline-none focus:ring-0 border-none size-8"
              >
                <ArrowUp className="size-5" />
              </button>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};
