import React, { useEffect, useState } from "react";
import {
  EllipsisVertical,
} from "lucide-react";
import { Dropdown } from "antd";
import type { MenuProps } from "antd";
import {
  Conversation,
} from "../../clients/sol";

interface ConversationHeaderProps {
  currentConversationId: string | null;
  conversation: Conversation;
  newConversationName: string;
  setNewConversationName: (name: string) => void;
  updateConversationName: () => Promise<void>;
  refereshConversationList: () => void;
  inputRef: React.RefObject<HTMLInputElement>;
  conversationMenuItems: MenuProps["items"];
}

export const ConversationHeader: React.FC<ConversationHeaderProps> = ({
  currentConversationId,
  conversation,
  newConversationName,
  setNewConversationName,
  updateConversationName,
  refereshConversationList,
  inputRef,
  conversationMenuItems,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setNewConversationName(conversation?.name || "New Chat");
  }, [currentConversationId, conversation]);

  const handleSubmit = async () => {
    if (!currentConversationId || !newConversationName) return;

    try {
      await updateConversationName();
      setIsEditing(false);
      refereshConversationList();
    } catch (error) {
      console.error("Error updating name:", error);
    }
  };

  return (
    <div className="text-lg font-semibold text-black flex items-center justify-between gap-2 w-full">
      <div className="flex-1">
        <input
          ref={inputRef}
          type="text"
          value={newConversationName}
          onFocus={() => setIsEditing(true)}
          onBlur={async () => {
            if (isEditing && newConversationName) {
              await handleSubmit();
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              inputRef.current?.blur();
            }
          }}
          placeholder="New Chat"
          onChange={(e) => setNewConversationName(e.target.value)}
          className="w-full pl-2 pr-4 py-1 rounded-lg bg-transparent border border-transparent hover:border hover:border-neutral-300 hover:border-dashed placeholder:text-black truncate"
        />
      </div>
      <Dropdown menu={{ items: conversationMenuItems }} trigger={["click"]}>
        <div className="size-6 p-0.5 rounded-full flex items-center justify-center cursor-pointer">
          <EllipsisVertical className="w-full h-full text-neutral-500" />
        </div>
      </Dropdown>
    </div>
  );
};
