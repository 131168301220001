import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import _ from "lodash";
import { Menu } from "./Menu";
import FloatingActionButton from "../FloatingActionButton";
import Toggle, { featureTypes, On, Off } from "../FeatureToggle";
import DashBackButton from "../../images/dash-back.svg";
import SearchInput from "../Search/SearchInput";
import { useCurrentUser, useProject } from "../../hooks";

export function DesktopSidebar() {
  const { project } = useProject();
  const currentUser = useCurrentUser();
  const location = useLocation();
  const [isAssistantPage, setIsAssistantPage] = useState(false);

  useEffect(() => {
    const paths = location.pathname.split("/");
    if (paths[3] === "assistant") {
      setIsAssistantPage(true);
    } else {
      setIsAssistantPage(false);
    }
  }, [location]);

  return (
    <>
      <div
        style={{
          display: "flex",
          paddingTop: "25px",
          paddingBottom: "25px",
          alignItems: "center",
          marginTop: 10,
          fontSize: "16px",
          lineHeight: "29px",
          justifyContent: "space-between",
        }}
        className=""
      >
        <div>
          <div
            style={{
              display: "inline-block",
              alignItems: "center",
              marginLeft: "5px",
              marginRight: "16px",
            }}
          >
            <Link to="/">
              <img
                style={{ height: "20px" }}
                src={DashBackButton}
                // onClick={goToDashboard}
              />{" "}
            </Link>
          </div>

          <div
            style={{
              display: "inline-block",
              verticalAlign: "middle",
              marginRight: "10px",
              textAlign: "right",
            }}
            className=""
          >
            <div
              style={{
                // display: "flex",
                // alignItems: "center",
                fontFamily: "Avenir Next",
                fontWeight: 400,
                fontSize: "24px",
              }}
              data-cy="project-title"
              className={`${isAssistantPage ? "max-w-[10rem] truncate" : ""}`}
            >
              {project.name}
            </div>
          </div>
        </div>

        <Toggle user={currentUser} feature={featureTypes.SEARCH}>
          <On>
            <div style={{ float: "right" }}>
              <SearchInput />
            </div>
          </On>
          <Off>
            <></>
          </Off>
        </Toggle>
      </div>

      <Menu project={project} />
      <FloatingActionButton />
    </>
  );
}
