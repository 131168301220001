export type ModelId = 'claude-3-5-sonnet-20241022' | 'gpt-4o';

type SupportedModel = {
  id: ModelId;
  name: string;
};

export const supportedModels: Record<ModelId, SupportedModel> = {
  "gpt-4o": {
    id: "gpt-4o",
    name: "GPT-4o",
  },
  "claude-3-5-sonnet-20241022": {
    name: "Claude 3.5 Sonnet",
    id: "claude-3-5-sonnet-20241022",
  },
};
