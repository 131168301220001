import React from "react";
import { Button, Input, Modal } from "antd";

export const SettingsModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => (
  <Modal
    title="AI Settings"
    open={open}
    onCancel={onClose}
    footer={[
      <Button key="close" onClick={onClose}>
        Close
      </Button>,
    ]}
  >
    <div className="flex flex-col gap-4">
      <div>
        <h3 className="font-medium mb-2">Model Settings</h3>
        <Input.TextArea
          rows={4}
          placeholder="Add custom instructions for the AI..."
          className="mb-4"
        />
      </div>
      {/* Add more settings as needed */}
    </div>
  </Modal>
);
