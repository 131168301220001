export interface Message {
  id?: string;
  text: string;
  sender: "user" | "assistant" | "system";
  parentMessageId?: string;
  timestamp: string;
  prompt?: string;
  retrievalParams?: ActivityRetrievalParams;
  referenceData?: any;
}

export interface Conversation {
  id: string;
  name: string;
  project_id: string;
  slack_channel_id?: string;
  created_at: string;
  start_date: string;
  end_date: string;
}

export type SendMessageResponse = {
  prompt: string;
  response: string;
  retrievalParams?: ActivityRetrievalParams;
  referenceData?: any;
};

type ActivityRetrievalStrategy =
  | "vector_retrieval"
  | "date_retrieval"
  | "scoped_retrieval";

type ActivityRetrievalParams = {
  message: string;
  type: ActivityRetrievalStrategy;
  startDate: string;
  endDate: string;
  rationale?: string;
  options?: {
    collection?: string;
    matchText?: string;
  };
};

export class AssistantClient {
  private baseUrl: string;

  constructor() {
    if (process.env.REACT_APP_ENV === "dev") {
      this.baseUrl = "http://localhost:8080/test";
    } else {
      this.baseUrl = "https://api.aloa.dev/test";
    }
  }

  async newConversation({
    projectId,
    userId,
    slackChannelId,
    dateRange,
  }: {
    projectId?: string;
    userId: string;
    slackChannelId: string;
    dateRange: { start: string; end: string };
  }): Promise<string> {
    try {
      if (!projectId) {
        throw new Error("Project id required to create project");
      }

      const response = await fetch(
        `${this.baseUrl}/assistant/conversations/new`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            projectId,
            userId,
            slackChannelId,
            dateRange,
          }),
        }
      );
      const { conversationId } = await response.json();
      return conversationId;
    } catch (error) {
      console.error("Error creating conversation:", error);
      throw error;
    }
  }

  async sendMessage({
    conversationId,
    message,
    model,
    demo,
    regenerate,
  }: {
    conversationId: string;
    message: string;
    model?: string;
    demo?: boolean;
    regenerate?: {
      messageId: string;
    };
  }): Promise<SendMessageResponse> {
    const url = demo
      ? `${this.baseUrl}/assistant/conversations/${conversationId}/mockCompletion`
      : `${this.baseUrl}/assistant/conversations/${conversationId}/completion`;

    const requestBody: any = {
      message,
      stream: false,
      model: model ?? "gpt-4o",
    };

    if (regenerate) {
      requestBody.regenerate = regenerate;
    }

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    return response.json();
  }

  async sendMessageWithStream({
    conversationId,
    message,
    model,
    demo,
    regenerate,
    connectionHandler,
    progressHandler,
    completionHandler,
  }: {
    conversationId: string;
    message: string;
    model?: string;
    demo?: boolean;
    regenerate?: {
      messageId: string;
    };
    connectionHandler?: (controller: AbortController) => void;
    progressHandler?: (partial: string) => void;
    completionHandler?: ({
      prompt,
      response,
      retrievalParams,
    }: SendMessageResponse) => void;
  }): Promise<SendMessageResponse> {
    const url = demo
      ? `${this.baseUrl}/assistant/conversations/${conversationId}/mockCompletion`
      : `${this.baseUrl}/assistant/conversations/${conversationId}/completion`;

    const abortController = new AbortController();
    let chunks: string[] = [];
    let buffer = ""; // Add a buffer for incomplete chunks

    try {
      const requestBody: any = {
        message,
        stream: true,
        model: model ?? "gpt-4o",
      };

      if (regenerate) {
        requestBody.regenerate = regenerate;
      }

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        signal: abortController.signal,
        body: JSON.stringify(requestBody),
      });

      if (!response.ok || !response.body) {
        throw new Error("Failed to send message");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");

      return await new Promise(async (resolve, reject) => {
        try {
          while (true) {
            const { done, value } = await reader.read();
            if (done) {
              break;
            }

            const chunk = decoder.decode(value, { stream: true });
            buffer += chunk;

            // Split on newlines, keeping any incomplete chunk in the buffer
            const lines = buffer.split("\n");
            buffer = lines.pop() || ""; // Keep the last potentially incomplete line in buffer

            for (const line of lines) {
              if (line.trim().startsWith("data: ")) {
                try {
                  const jsonStr = line.replace("data: ", "").trim();
                  const event = JSON.parse(jsonStr);

                  switch (event.type) {
                    case "connected":
                      if (connectionHandler) {
                        connectionHandler(abortController);
                      }
                      break;
                    case "progress":
                      if (progressHandler) {
                        progressHandler(event.data);
                        chunks.push(event.data);
                      }
                      break;
                    case "completed":
                      const data = event.data;
                      if (completionHandler) {
                        completionHandler(data);
                      }
                      resolve(data);
                      break;
                  }
                } catch (parseError) {
                  console.warn("Error parsing chunk:", parseError);
                  console.warn("Problematic line:", line);
                  // Continue processing other chunks instead of failing completely
                  continue;
                }
              }
            }
          }

          // Handle any remaining data in buffer
          if (buffer.trim()) {
            try {
              const line = buffer.trim();
              if (line.startsWith("data: ")) {
                const jsonStr = line.replace("data: ", "").trim();
                const event = JSON.parse(jsonStr);
                if (event.type === "completed") {
                  const data = event.data;
                  if (completionHandler) {
                    completionHandler(data);
                  }
                  resolve(data);
                }
              }
            } catch (parseError) {
              console.warn("Error parsing final buffer:", parseError);
            }
          }
        } catch (error) {
          reject(error);
        }
      });
    } catch (error) {
      console.error("Got error handling message stream:", error);
      return {
        prompt: "",
        response: chunks.join(""),
        retrievalParams: undefined,
        referenceData: [],
      };
    }
  }

  async fetchConversationsForUser(
    projectId: string,
    userId: string,
    slackChannelId?: string
  ): Promise<Conversation[]> {
    try {
      const response = await fetch(
        `${this.baseUrl
        }/assistant/conversations?userId=${userId}&projectId=${projectId}${slackChannelId ? `&slackId=${slackChannelId}` : ""
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const conversations = await response.json();

      return conversations.sort((a, b) =>
        a.created_at > b.created_at ? -1 : 1
      );
    } catch (error) {
      console.error("Error fetching conversations:", error);
      return [];
    }
  }

  async fetchConversationMessages(conversationId: string): Promise<Message[]> {
    const response = await fetch(
      `${this.baseUrl}/assistant/conversations/${conversationId}/messages`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();
    return data.messages;
  }

  async renameConversation(conversationId: string, name: string) {
    const response = await fetch(
      `${this.baseUrl}/assistant/conversations/${conversationId}/rename`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
        }),
      }
    );

    return response.json();
  }

  async deleteConversation(conversationId: string) {
    const response = await fetch(
      `${this.baseUrl}/assistant/conversations/${conversationId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.json();
  }

  async autonameConversation({
    conversationId,
    message,
    response,
  }): Promise<void> {
    await fetch(
      `${this.baseUrl}/assistant/conversations/${conversationId}/autoname`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userMessage: message,
          aiResponse: response,
        }),
      }
    );
  }

  async updateConversationDateRange({
    conversationId,
    start,
    end,
  }: {
    conversationId: string;
    start: Date;
    end: Date;
  }) {
    await fetch(
      `${this.baseUrl}/assistant/conversations/${conversationId}/dateRange`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          start: start.toISOString(),
          end: end.toISOString(),
        }),
      }
    );
  }

  async sendFeedback(feedback: any) {
    const response = await fetch(
      `${this.baseUrl}/assistant/conversations/feedback`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ feedback }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  }
}

export const assistantClient = new AssistantClient();
