import { assistantClient } from "../../../clients/sol/index";
import { Modal } from "antd";
import React, { useState } from "react";

function FeedbackModal({ open, onClose }) {
  const [notes, setNotes] = useState("");
  const sendFeedback = async () => {
    try {
      if (notes === "") {
        onClose();
        return;
      }

      const feedback = {
        id: open?.id,
        notion_page_id: open?.notionId ?? null,
        notes: notes,
      };

      const { id, notionId } = await assistantClient.sendFeedback(feedback);

      // reset notes
      setNotes("");
      onClose();
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Modal
      open={open ?? false}
      onCancel={onClose}
      footer={null}
      style={{
        borderRadius: "16px",
      }}
    >
      <div className="flex flex-col">
        <div className="text-2xl font-semibold">Feedback recieved!</div>
        <div className="my-10 flex flex-col gap-4">
          <div>Do you have any additional notes?</div>
          <textarea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            className="border border-neutral-300 rounded-xl p-2"
            rows={8}
          />
        </div>
        <div className="flex items-center justify-end">
          <div
            className="bg-blue-600 text-white rounded-lg px-4 py-2 cursor-pointer hover:bg-blue-700"
            onClick={sendFeedback}
          >
            Send
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default FeedbackModal;
