import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Markdown from "react-markdown";
import { useCurrentUser } from "../../hooks";
import {
  Check,
  ChevronDown,
  ChevronUp,
  Copy,
  RotateCcw,
  ScrollText,
  ThumbsDown,
  ThumbsUp,
} from "lucide-react";
import { Popover, type MenuProps } from "antd";
import {
  assistantClient,
  Message,
  SendMessageResponse,
} from "../../clients/sol";
import { TextBox } from "./TextBox";
import { ConversationHeader } from "./ConversationHeader";
import { demoCommands, handleDemoCommand } from "./demo";
import { posthog } from "../../clients/posthog";
import manageLogo from "../../images/app_icon.png";
import slackLogo from "../../images/slack_icon.png";
import FigmaLogo from "../../images/figma_icon.png";
import NotionLogo from "../../images/notion_icon.png";
import ZoomLogo from "../../images/zoom_icon.png";
import { ModelId } from "./config";

// Disable this if we want streaming disabled
let streamEnabled = true;

export function Chat({
  conversation,
  newConversationMessage,
  setNewConversationMessage,
  setDebugModalMessage,
  refereshConversationList,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  updateConversationDateRange,
  openFeedbackModal,
  setOpenFeedbackModal,
  model,
  setModel,
}: {
  conversation;
  newConversationMessage: string;
  setNewConversationMessage: (message: string) => void;
  setDebugModalMessage: (message: Message | null) => void;
  refereshConversationList: () => void;
  startDate?: Date;
  setStartDate: (date) => void;
  endDate?: Date;
  setEndDate: (date) => void;
  updateConversationDateRange?: (start: Date, end: Date) => void;
  openFeedbackModal: { id: string; notionId: string } | null;
  setOpenFeedbackModal: (open: { id: string; notionId: string } | null) => void;
  model: ModelId;
  setModel: (model: ModelId) => void;
}) {
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dotCount, setDotCount] = useState(1);
  const [aiLoading, setAiLoading] = useState(false);
  const [newConversationName, setNewConversationName] = useState("");
  const [inProgressMessageChunks, setInProgressMessageChunks] = useState<
    string[]
  >([]);
  const [messageCopied, setMessageCopied] = useState(false);
  const [feedbackStates, setFeedbackStates] = useState<{
    [key: number]: "positive" | "negative" | null;
  }>({});

  const messageBoxRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const loadingController = useRef<AbortController | undefined>(undefined);

  const { projectId = undefined, conversationId = "" } = useParams();

  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  const conversationMenuItems: MenuProps["items"] = [
    {
      key: "rename",
      label: "Rename",
      onClick: () => {
        inputRef.current?.focus();
      },
    },
    {
      key: "delete",
      label: "Delete",
      danger: true,
      onClick: async () => {
        // Delete conversation and refresh list
        await assistantClient.deleteConversation(conversationId);
        refereshConversationList();
        navigate("/p/" + projectId + "/assistant");
      },
    },
  ];

  const updateConversationName = async () => {
    try {
      await assistantClient.renameConversation(
        conversationId,
        newConversationName
      );
      console.log("Conversation name updated:", newConversationName);
    } catch (error) {
      console.error("Error updating conversation name:", error);
    }
  };

  useEffect(() => {
    if (newConversationMessage) {
      handleSubmitMessage(newConversationMessage);
      setNewConversationMessage("");
    }
  }, [newConversationMessage]);

  useEffect(() => {
    if (aiLoading) {
      const interval = setInterval(() => {
        setDotCount((prev) => (prev % 3) + 1);
      }, 500);
      return () => clearInterval(interval);
    }
  }, [aiLoading]);

  // Automate scrolling of the message box when new messages or in-progress message chunks are added
  useEffect(() => {
    if (messageBoxRef.current) {
      messageBoxRef.current.scrollTo({
        top: messageBoxRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [messages, inProgressMessageChunks]);

  // Load conversation messages when selecting a conversation
  useEffect(() => {
    if (!newConversationMessage) {
      fetchConversationMessages(conversationId);
    }
  }, [conversationId]);

  // Fetch conversation messages
  const fetchConversationMessages = async (conversationId: string) => {
    try {
      const messages = await assistantClient.fetchConversationMessages(
        conversationId
      );
      setMessages(messages);
    } catch (error) {
      console.error("Error fetching conversation messages:", error);
      setMessages([]);
    }
  };

  const sendMessage = async (
    conversationId: string,
    message: string,
    regenerate?: {
      messageId: string;
    }
  ): Promise<SendMessageResponse | null> => {
    setAiLoading(true);

    try {
      let completion: SendMessageResponse | null;

      if (streamEnabled) {
        const sendMessageResponse = await assistantClient.sendMessageWithStream(
          {
            conversationId,
            message,
            model,
            regenerate,
            connectionHandler: (controller) => {
              loadingController.current = controller;
              setInProgressMessageChunks([]);
            },
            progressHandler: (partial) => {
              setInProgressMessageChunks((prev) => [...prev, partial]);
            },
            completionHandler: (finalResponse) => {
              completion = finalResponse;
              setAiLoading(false);
            },
          }
        );

        completion = sendMessageResponse;
      } else {
        const sendMessageResponse = await assistantClient.sendMessage({
          conversationId,
          message,
        });
        completion = sendMessageResponse;
      }

      if (messages.length === 0) {
        await assistantClient.autonameConversation({
          conversationId,
          message,
          response: completion?.response,
        });
        refereshConversationList();
      }

      return completion;
    } catch (error) {
      console.error("Error sending message:", error);
      return null;
    } finally {
      setInProgressMessageChunks([]);
      setAiLoading(false);
    }
  };

  const sendDemoMessage = async (
    conversationId: string,
    message: string
  ): Promise<SendMessageResponse | null> => {
    setAiLoading(true);

    try {
      const demoCommand = handleDemoCommand(message);
      if (!demoCommand) {
        throw new Error("Invalid demo command");
      }

      if (streamEnabled) {
        const completion = await assistantClient.sendMessageWithStream({
          conversationId,
          message,
          demo: true,
          connectionHandler: (controller) => {
            loadingController.current = controller;
            setInProgressMessageChunks([]);
          },
          progressHandler: (partial) => {
            setInProgressMessageChunks((prev) => [...prev, partial]);
          },
          completionHandler: () => {
            setInProgressMessageChunks([]);
            setAiLoading(false);
          },
        });

        return completion;
      } else {
        const completion = await assistantClient.sendMessage({
          conversationId,
          message,
          demo: true,
        });
        return completion;
      }
    } catch (error) {
      console.error("Error sending demo message:", error);
      return null;
    } finally {
      setAiLoading(false);
    }
  };

  const handleSubmitMessage = async (message: string) => {
    setIsLoading(true);
    setDotCount(1);

    try {
      const isDemoCommand = message.startsWith("!demo_");
      const newMessage: Message = {
        text: isDemoCommand ? demoCommands[message].userMessage : message,
        sender: "user",
        timestamp: new Date().toLocaleTimeString(),
      };
      setMessages((prev) => [...prev, newMessage]);

      try {
        const response = isDemoCommand
          ? await sendDemoMessage(conversationId, message)
          : await sendMessage(conversationId, message);

        if (response) {
          const aiMessage: Message = {
            text: response?.response,
            prompt: response?.prompt,
            referenceData: response?.referenceData,
            sender: "assistant",
            timestamp: new Date().toLocaleTimeString(),
            retrievalParams: response?.retrievalParams,
          };
          setMessages((prev) => [...prev, aiMessage]);
        }
      } catch (error) {
        const errorMessage: Message = {
          text: "Sorry, I encountered an error processing your message.",
          sender: "assistant",
          timestamp: new Date().toLocaleTimeString(),
        };
        setMessages((prev) => [...prev, errorMessage]);
      }
    } catch (error) {
      console.error("Error in conversation:", error);
      const errorMessage: Message = {
        text: "Sorry, something went wrong. Please try again.",
        sender: "assistant",
        timestamp: new Date().toLocaleTimeString(),
      };
      setMessages((prev) => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRegenerateMessage = async (message: Message) => {
    try {
      const prompt = messages.find(
        (m) => m.id === message.parentMessageId
      )?.text;
      setMessages((prev) => prev.filter((m) => m.id !== message.id));

      const response = await sendMessage(conversationId, prompt!, {
        messageId: message.id!,
      });

      if (response) {
        const aiMessage: Message = {
          text: response?.response,
          prompt: response?.prompt,
          referenceData: response?.referenceData,
          sender: "assistant",
          timestamp: new Date().toLocaleTimeString(),
          retrievalParams: response?.retrievalParams,
        };
        setMessages((prev) => [...prev, aiMessage]);
      }
    } catch (error) {
      console.error("Error regenerating message:", error);
    }
  };

  const handleFeedback = async (
    messageIndex: number,
    isPositive: boolean,
    message: Message
  ) => {
    try {
      const feedback = {
        user: {
          id: currentUser?.id,
          name: currentUser?.name,
          avatar: currentUser?.image_thumbnail,
        },
        project: {
          id: projectId,
          name: null,
        },
        conversation: {
          id: conversationId,
          name: null,
        },
        query: message.prompt,
        retrieval_params: message.retrievalParams,
        response: message.text,
        feedback: isPositive ? "positive" : "negative",
      };

      // Prevent multiple feedback submissions for the same message
      if (feedbackStates[messageIndex]) {
        return;
      }
      // Update the local feedback state
      setFeedbackStates((prev) => ({
        ...prev,
        [messageIndex]: isPositive ? "positive" : "negative",
      }));
      // Send feedback to posthog
      // Get the user's message that preceded this assistant message
      const userMessage =
        messageIndex > 0 ? messages[messageIndex - 1].text : null;

      const { id, notionId } = await assistantClient.sendFeedback(feedback);

      posthog.capture("conversation_feedback", {
        timestamp: new Date().toISOString(),
        userId: currentUser.id,
        projectId: projectId,
        feedbackType: isPositive ? "positive" : "negative",
        conversationId: conversationId,
        messageIndex: messageIndex,
        assistantMessageContent: messages[messageIndex].text,
        userMessageContent: userMessage, // The user's message that prompted this response
      });

      setOpenFeedbackModal({
        id: id,
        notionId: notionId,
      });
    } catch (error) {
      console.error("Error sending feedback:", error);
    }
  };

  const renderMessages = (
    messages: any,
    inProgressMessageChunks: string[],
    formatMessageWithLinks: (text: string) => string
  ) => {
    const messagesToRender =
      inProgressMessageChunks.length > 0
        ? [...messages, processMessageChunks(inProgressMessageChunks)]
        : messages || [
            {
              text: "Sorry there has been an error. Please try again later.",
              sender: "assistant",
              timestamp: new Date().toLocaleTimeString(),
            },
          ];

    return messagesToRender.map((message, index) => {
      const isAssistant = message.sender === "assistant";
      const feedback = feedbackStates[index];
      const isCurrentlyStreaming =
        inProgressMessageChunks.length > 0 &&
        index === messagesToRender.length - 1;

      return (
        <div
          key={index}
          className={`flex flex-col ${
            message.sender === "user" ? "items-end" : "items-start"
          } group gap-1`}
        >
          <div
            className={`flex flex-row ${
              isAssistant ? "w-full" : "max-w-[70%]"
            }`}
          >
            <div className="flex flex-col gap-1">
              {/* {isAssistant && (
                <Sources
                  referenceData={message?.referenceData}
                  loading={isCurrentlyStreaming}
                />
              )} */}
              {isAssistant && (
                <AdvanceReasoning reasoning={message?.reasoning} />
              )}
              <div className="flex items-start mt-2">
                {isAssistant && (
                  <img
                    src={manageLogo}
                    alt="Assistant"
                    className="size-6 rounded-full"
                  />
                )}
                <div
                  className={`${isAssistant ? "px-3" : "p-3"} rounded-lg  ${
                    message.sender === "user"
                      ? "bg-neutral-500/10 text-neutral-600"
                      : "text-gray-800"
                  }`}
                >
                  <div className="prose prose-sm max-w-none">
                    <div className="[&_code]:break-all [&_code]:whitespace-pre-wrap [&_pre]:whitespace-pre-wrap [&_pre]:break-all overflow-x-auto">
                      <Markdown
                        components={{
                          img: ({ node, ...props }) => {
                            if (props.alt === "Manage") {
                              return (
                                <img
                                  src={manageLogo}
                                  style={{
                                    verticalAlign: "middle",
                                    width: "16px",
                                    height: "16px",
                                  }}
                                />
                              );
                            }
                            if (props.alt === "Slack") {
                              return (
                                <img
                                  src={slackLogo}
                                  style={{
                                    verticalAlign: "middle",
                                    width: "14px",
                                    height: "14px",
                                  }}
                                />
                              );
                            }
                            return (
                              <img
                                {...props}
                                style={{
                                  verticalAlign: "middle",
                                  width: "16px",
                                  height: "16px",
                                }}
                              />
                            );
                          },
                          a: ({ node, ...props }) => (
                            <a
                              {...props}
                              target="_blank"
                              rel="noopener noreferrer"
                            />
                          ),
                        }}
                      >
                        {formatMessageWithLinks(message.text)}
                      </Markdown>
                    </div>
                  </div>
                </div>
              </div>
              {isAssistant && (
                <Sources
                  referenceData={message?.referenceData}
                  loading={isCurrentlyStreaming}
                />
              )}
              {isAssistant && (
                <div
                  className={`mt-2 flex items-center gap-4 text-neutral-400 opacity-0 transition-opacity group-hover:opacity-100 px-10`}
                >
                  {!messageCopied ? (
                    <Copy
                      onClick={() => {
                        navigator.clipboard.writeText(message.text);
                        setMessageCopied(true);
                        setTimeout(() => setMessageCopied(false), 3000);
                      }}
                      strokeWidth={2.3}
                      className="size-3 hover:text-neutral-700 cursor-pointer"
                    />
                  ) : (
                    <Check
                      strokeWidth={2.3}
                      className="size-3 hover:text-neutral-700 cursor-pointer"
                    />
                  )}
                  <ThumbsUp
                    onClick={() => handleFeedback(index, true, message)}
                    strokeWidth={2.3}
                    className={`size-3 cursor-pointer ${
                      feedback === "positive"
                        ? "text-green-500 hover:text-green-600"
                        : "hover:text-neutral-700"
                    } ${
                      feedback === "negative"
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }`}
                  />
                  <ThumbsDown
                    onClick={() => handleFeedback(index, false, message)}
                    strokeWidth={2.3}
                    className={`size-3 cursor-pointer ${
                      feedback === "negative"
                        ? "text-red-500 hover:text-red-600"
                        : "hover:text-neutral-700"
                    } ${
                      feedback === "positive"
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }`}
                  />
                  <ScrollText
                    onClick={() => {
                      setDebugModalMessage(message);
                    }}
                    strokeWidth={2.3}
                    className="size-3 hover:text-neutral-700 cursor-pointer"
                  />
                  {/* TODO: Add regeneration functionality
                  <RotateCcw
                    onClick={() => {
                      handleRegenerateMessage(message);
                    }}
                    strokeWidth={2.7}
                    className="size-3 hover:text-neutral-700 cursor-pointer"
                  /> */}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="flex-1 flex flex-col bg-white border-neutral-200 drop-shadow-md relative rounded-xl max-w-[60rem]">
      {conversationId && (
        <div
          className="w-full h-14 flex items-center py-2 px-4"
          style={{ borderBottom: "1px solid #e5e7eb" }}
        >
          <ConversationHeader
            currentConversationId={conversationId}
            conversation={conversation}
            newConversationName={newConversationName}
            setNewConversationName={setNewConversationName}
            updateConversationName={updateConversationName}
            refereshConversationList={refereshConversationList}
            inputRef={inputRef}
            conversationMenuItems={conversationMenuItems}
          />
        </div>
      )}

      <div
        ref={messageBoxRef}
        className="flex-1 overflow-y-auto pt-4 px-5 relative"
      >
        <div className="flex flex-col gap-2 pb-10">
          {renderMessages(
            messages,
            inProgressMessageChunks,
            formatMessageWithLinks
          )}
        </div>
      </div>

      {aiLoading && (
        <div className="px-4 pb-2 z-20">
          <p className="text-sm italic text-gray-500">
            Assistant is thinking{".".repeat(dotCount)}
          </p>
        </div>
      )}

      <TextBox
        isLoading={isLoading}
        onSubmit={handleSubmitMessage}
        stopGeneration={() => {
          if (loadingController.current) {
            loadingController.current.abort();
            setInProgressMessageChunks([]);
          }
        }}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        updateConversationDateRange={updateConversationDateRange}
        model={model}
        setModel={setModel}
      />
    </div>
  );
}

const ShowAllBox = ({ remainingSources }) => {
  const displayedIcons = remainingSources.slice(0, 6);
  const remainingCount = remainingSources.length - 6;

  return (
    <div className="bg-neutral-200/40 rounded-xl flex flex-col p-2 justify-between h-32">
      <div className="text-xs font-medium">Show all</div>
      <div className="flex items-center gap-1 mt-2">
        {displayedIcons.map((source, index) => (
          <img
            key={index}
            src={getSourceLogo(source.source)}
            alt={source.source}
            className="size-3 rounded-sm"
          />
        ))}
        {remainingCount > 0 && (
          <span className="text-xs text-neutral-500 font-medium ml-1">
            +{remainingCount}
          </span>
        )}
      </div>
    </div>
  );
};

const Sources = ({ referenceData = [], loading = false }) => {
  if (loading) {
    return null;
  }

  if (!referenceData || referenceData.length === 0) {
    return null;
  }

  const processDeepLink = (link, source) => {
    if (!link) return "";
    const urlMatch = link.match(/\[.*?\]\((.*?)\)/);
    const cleanUrl = urlMatch ? urlMatch[1] : link;

    if (
      source?.toLowerCase() === "slack" &&
      cleanUrl.includes("slack.com/archives")
    ) {
      const matches = cleanUrl.match(
        /https:\/\/(.*?)\.slack\.com\/archives\/(.*?)\/(.*)/
      );
      if (matches) {
        const [, team, channel, timestamp] = matches;
        return `slack://channel?team=${team}&id=${channel}&message=${timestamp}`;
      }
    }

    return cleanUrl;
  };

  const formatRelativeTime = (dateStr?: string) => {
    if (!dateStr) return "";

    // Parse the date string (handles both ISO and locale formats)
    const parsedDate = new Date(dateStr);
    if (isNaN(parsedDate.getTime())) return "";

    const now = new Date();
    const diffInSeconds = Math.floor(
      (now.getTime() - parsedDate.getTime()) / 1000
    );

    // Less than a minute
    if (diffInSeconds < 60) {
      return "now";
    }

    // Less than an hour
    if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes}m`;
    }

    // Less than a day
    if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours}h`;
    }

    // Less than 3 days
    if (diffInSeconds < 259200) {
      // 3 * 86400
      const days = Math.floor(diffInSeconds / 86400);
      return `${days}d`;
    }

    // More than 3 days - return formatted date
    return parsedDate.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year:
        parsedDate.getFullYear() !== now.getFullYear() ? "numeric" : undefined,
    });
  };

  const content = (
    <div className="w-[20rem] h-[16rem] overflow-y-scroll overflow-x-hidden">
      {referenceData.map((source: any, index) => {
        const href = processDeepLink(source?.deeplink, source?.source);
        return (
          <div key={index} className="group hover:bg-neutral-100">
            <a
              className="block cursor-pointer px-3 py-2"
              href={href}
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex flex-col gap-6">
                <div className="text-xs text-neutral-600 mb-1 whitespace-normal">
                  {source.text}
                </div>
                <div className="flex justify-between items-center">
                  <div className="flex items-center gap-1">
                    <img
                      src={getSourceLogo(source.source)}
                      alt={source.source}
                      className="size-3 rounded-sm"
                    />
                    <span className="text-xs font-medium text-neutral-500">
                      {source.source}
                    </span>
                  </div>
                  <div className="flex flex-col items-end">
                    <div className="text-[10px] text-neutral-400">
                      {formatRelativeTime(source?.timestamp)}
                    </div>
                    <div className="text-neutral-400 text-xs">
                      {source?.user?.name
                        ? `${source?.user?.name.slice(0, 10)}..`
                        : source?.user}
                    </div>
                  </div>
                </div>
              </div>
            </a>
            <div className="bg-neutral-200 w-full p-[0.5px]" />
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="inline-flex items-center gap-2 mt-2 px-8">
      <Popover
        content={content}
        title={<div className="py-1">Sources</div>}
        trigger="click"
        placement="top"
        overlayInnerStyle={{
          borderRadius: "0.5rem",
          padding: 0,
        }}
        overlayClassName="[&_.ant-popover-inner-content]:p-0"
      >
        <div
          className="pr-3 pl-2 py-2 text-sm font-medium rounded-xl bg-white hover:bg-neutral-50 flex items-center gap-4 cursor-pointer"
          style={{
            border: "1px solid #c7c7c7",
          }}
        >
          Sources
          <div className="flex items-center ml-[-1px]">
            {referenceData.slice(0, 2).map((source: any, index) => (
              <img
                key={index}
                src={getSourceLogo(source.source)}
                alt={source.source}
                className="size-3 rounded-sm ring-1 ring-white"
              />
            ))}
            {referenceData.length > 2 && (
              <div className="size-3 rounded-xs flex items-center justify-center text-[10px] font-semibold text-neutral-400 ring-1 ring-white ml-[6px]">
                +{referenceData.length - 2}
              </div>
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
};

const getSourceLogo = (source) => {
  const logoMap = {
    slack: slackLogo,
    manage: manageLogo,
  };
  return logoMap[source.toLowerCase()] || manageLogo;
};

const SourceBox = ({ source }) => {
  return (
    <div className="bg-neutral-200/40 rounded-xl flex flex-col p-2 justify-between h-32">
      <div className="text-xs inline-block whitespace-pre-wrap overflow-ellipsis h-20 overflow-hidden mb-3">
        {source?.text}
      </div>
      <div className="flex items-center gap-1">
        <img
          src={getSourceLogo(source.source)}
          alt={source.source}
          className="size-3 rounded-sm"
        />
        <div className="text-xs font-semibold text-neutral-500">
          {source.source}
        </div>
      </div>
    </div>
  );
};

const AdvanceReasoning = ({ reasoning = [] }): any => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  if (!reasoning || reasoning.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4 px-3 mb-8">
      <div
        className="text-xl flex items-center justify-between cursor-pointer group"
        onClick={toggleExpand}
      >
        <div className="flex items-center gap-3 font-medium text-neutral-400">
          <div>Advance Reasoning</div>
        </div>
        {isExpanded ? (
          <ChevronUp className="size-5 text-neutral-400 " />
        ) : (
          <ChevronDown className="size-5 text-neutral-400 " />
        )}
      </div>

      {isExpanded && (
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-2">
            <div className="font-medium">Optimizing Query</div>
            <div className="flex items-center gap-6">
              <div className="h-full p-[0.5px] bg-neutral-200" />
              <div className="py-2 text-neutral-500">
                Fetch details for "clarification 8"
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="font-medium">Analyzing Intructions</div>
            <div className="flex items-center gap-6">
              <div className="h-full p-[0.5px] bg-neutral-200" />
              <div className="py-2 text-neutral-500 flex flex-col gap-2">
                <div>Begin analyzing</div>
                <div>
                  Identified topic type <strong>Clarifications</strong>
                </div>
                <div>
                  Pulling type <strong>Clarifications</strong> instructions
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="font-medium">Reasoning with R1</div>
            <div className="flex items-center gap-6">
              <div className="h-full p-[0.5px] bg-neutral-200" />
              <div className="py-2 text-neutral-500 flex flex-col gap-2">
                <div>
                  This clarification focuses on the post-scanning data review
                  interface
                </div>
                <div>
                  Meet has outlined specific requirements for field permissions
                  and data flow
                </div>
                <div>
                  The interface splits into two distinct screens with different
                  purposes
                </div>
                <div>
                  Main screen shows extracted data with controlled edit
                  permissions
                </div>
                <div>Secondary screen handles item-level modifications</div>
                <div>
                  Key point: changes propagate automatically to maintain
                  consistency
                </div>
                <div>This solves previous issues with data synchronization</div>
                <div>
                  Ready to provide a structured breakdown of the clarification
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const formatMessageWithLinks = (text: string) => {
  if (!text) return text;

  text = cleanupMarkdown(text);

  const linkRegexes = {
    slack: /\[source:slack\]\((https:\/\/aloa\.slack\.com\/archives\/[^)]+)\)/g,
    manage: /\[source:manage\]\((https:\/\/[^)]+)\)/g,
  };

  let formattedText = text;

  // Replace Slack links
  formattedText = formattedText.replace(linkRegexes.slack, (_, url) => {
    return `[![Slack](Slack)](${url})`;
  });

  // Replace Manage links
  formattedText = formattedText.replace(linkRegexes.manage, (_, url) => {
    return `[![Manage](Manage)](${url})`;
  });

  return formattedText;
};

const processMessageChunks = (chunks: string[]): Message => {
  const text = cleanupMarkdown(chunks.join(""));
  return {
    text,
    sender: "assistant",
    timestamp: new Date().toLocaleTimeString(),
  };
};

const cleanupMarkdown = (text: string) => {
  return text.replace(/\*\*[ ]+(.+?)[ ]+\*\*/gm, "**$1**");
};
