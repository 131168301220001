import { Button, Input, Modal } from "antd";
import { Message } from "../../../clients/sol";

export const DebugModal = ({
  message,
  onClose,
}: {
  message: Message | null;
  onClose: () => void;
}) => (
  <Modal
    title="Message Debugger"
    open={message !== null}
    onCancel={onClose}
    footer={[
      <Button key="close" onClick={onClose}>
        Close
      </Button>,
    ]}
  >
    <div className="flex flex-col gap-2">
      <div>
        <h3 className="font-medium mb-2">LLM Message Prompt</h3>
        <Input.TextArea
          rows={12}
          value={message?.prompt}
          className="mb-4"
          contentEditable={false}
        />
      </div>

      <div>
        <h3 className="font-medium mb-2">Activity Retrieval Params</h3>
        <Input.TextArea
          rows={12}
          value={JSON.stringify(message?.retrievalParams ?? {}, null, 2)}
          className="mb-4"
          contentEditable={false}
        />
      </div>

      <div>
        <h3 className="font-medium mb-2">LLM Response</h3>
        <Input.TextArea
          rows={12}
          value={message?.text}
          className="mb-4"
          contentEditable={false}
        />
      </div>
    </div>
  </Modal>
);
