export const basename = process.env.REACT_APP_BASE_ROUTE ?? "";

export const current_app_version = 2.06;

export const aloa_gitbook_manual =
  "https://aloa.gitbook.io/aloa-project-manual/";

export const POSTHOG_FEATURE_FLAGS = {
  reports: "reports",
  assistant: "assistant",
};
