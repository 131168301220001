import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "antd";
import { styles } from "../../../styles/styles";
import { makeLinkForComponent } from "../../utils";
import actions from "../../../actions";
import { useCurrentUser } from "../../../hooks";
import { AppState } from "@/types";
import { MenuRow } from "./MenuRow";
import { useMenuRows } from "./useMenuRows";
import analytics_icon from "../../../images/analytics_icon.svg";
import start_icon from "../../../images/star.png";
import assistant_icon from "../../../images/assistant.png";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { POSTHOG_FEATURE_FLAGS } from "../../../constants";

export function Menu({ project }) {
  if (!project.addonsEnabled) {
    project = { ...project, addonsEnabled: [] };
  }

  const activePage = useSelector((state: AppState) => state.page);
  const { isCompact } = useCurrentUser();

  const dispatch = useDispatch();

  const { enabledPageDataListMain, enabledPageDataListAddons, loading } =
    useMenuRows(project.id);

  // Pull from the project object
  const reportingEnabled =
    (useFeatureFlagEnabled(POSTHOG_FEATURE_FLAGS.reports) &&
      project.performance_reports_enabled) ??
    false;

  const assistantEnabled = useFeatureFlagEnabled(
    POSTHOG_FEATURE_FLAGS.assistant
  );

  if (loading) return null;

  return (
    // @ts-ignore
    <div
      className="rounded sticky top-4"
      // @ts-ignore
      style={{ ...styles.stickDivStatus }}
    >
      {/* @ts-ignore */}
      <div style={styles.profileInfoCardStatus}>
        <div
          style={{
            display: "flex",
            fontSize: "15px",
            marginLeft: "5px",
            fontWeight: "600",
          }}
        >
          Main Menu
        </div>

        <div className="flex flex-col gap-2 my-3">
          {enabledPageDataListMain.map((data) => (
            <MenuRow
              key={data.id}
              data={data}
              link={makeLinkForComponent(data.id, project.id, data.type)}
              active={activePage === data.id}
            />
          ))}
        </div>

        <div
          style={{
            display: "flex",
            fontSize: "15px",
            marginLeft: "5px",
            fontWeight: "600",
            marginTop: "15px",
          }}
        >
          Add-ons
        </div>

        <div className="flex flex-col gap-2 my-3">
          {enabledPageDataListAddons.map((data) => (
            <MenuRow
              key={data.id}
              data={data}
              link={makeLinkForComponent(data.id, project.id, data.type)}
              active={activePage === data.id}
            />
          ))}
        </div>

        {reportingEnabled && (
          <>
            {" "}
            <div
              style={{
                display: "flex",
                fontSize: "15px",
                marginLeft: "5px",
                fontWeight: "600",
                marginTop: "15px",
              }}
            >
              Reports
            </div>
            <div className="flex flex-col gap-2 my-3">
              <MenuRow
                key={"performance"}
                data={{
                  title: "Performance",
                  image: analytics_icon,
                  imageHeight: "10px",
                }}
                link={`/p/${project.id}/performance`}
                active={activePage === "performance"}
              />
            </div>
          </>
        )}

        {assistantEnabled && (
          <>
            {" "}
            <div
              style={{
                display: "flex",
                fontSize: "15px",
                marginLeft: "5px",
                fontWeight: "600",
                marginTop: "15px",
              }}
            >
              Experimental
            </div>
            <div className="flex flex-col gap-2 my-3">
              <MenuRow
                key={"assistant"}
                data={{
                  title: "Assistant",
                  image: assistant_icon,
                  imageHeight: "20px",
                }}
                link={`/p/${project.id}/assistant`}
                active={activePage === "assistant"}
              />
            </div>
          </>
        )}

        <div className="flex flex-row gap-2 items-center text-sm ml-[20px] mt-5 text-neutral-500 ">
          <span>Compact</span>{" "}
          <Switch
            size="small"
            defaultChecked={false}
            checked={isCompact}
            onChange={(checked) => {
              dispatch(actions.user.updateUserSettingsCompactMode(checked));
            }}
          />
        </div>
      </div>
    </div>
  );
}
