export const demoCommands: any = {
  "!demo_1": {
    userMessage: "Can you provide a project status update?",
  },
  "!demo_2": {
    userMessage: "Please share the sprint review summary.",
  },
  "!demo_3": {
    userMessage: "What's the current bug triage status?",
  },
  "!demo_4": {
    userMessage: "Can you show the latest release notes?",
  },
};

export const handleDemoCommand = (command: string): any | null => {
  return demoCommands[command] || null;
};
