import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { styles } from "../../styles/styles";
import { db } from "../../firebase";
import actions from "../../actions";
import { Section } from "./Section.js";
import { Button, Select, message, Popconfirm, Switch, Modal } from "antd";
import CollaboratorCard from "./CollaboratorCard";
import InviteCard from "./InviteCard";
import SettingsCard from "./SettingsCard";
import "../App.css";
import { id, validateEmail } from "../utils";
import firebase from "../../firebase";
import { callCloudFunction } from "../../api/cloud-functions";
import DuplicationModal from "./DuplicationModal";
import { useUserRole } from "../../hooks/useUserRole";
import { useCurrentUserPermissions } from "../../hooks/useCurrentUserPermissions";
import { useCurrentUser } from "../../hooks";
import { AppState } from "@/types";
import _ from "lodash";
import { Project } from "@/types/project";
import { debounce } from "lodash";

const Option = Select.Option;

interface UserOption {
  id: string;
  email: string;
  name: string;
}

function ProjectSettings({ project, components }) {
  const [projectEditable, setProjectEditable] = useState<Partial<Project>>({});
  const [emailToInvite, setEmailtoInvite] = useState("");
  const [roleToInvite, setRoleToInvite] = useState("viewer");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [inviteLoading, setInviteLoading] = useState(false);
  const [inviteModalVisible, setInviteModalVisible] = useState(false);
  const [duplicationModalVisible, setDuplicationModalVisible] = useState(false);
  const navigate = useNavigate();
  const [userOptions, setUserOptions] = useState<UserOption[]>([]);
  const [allUsers, setAllUsers] = useState<UserOption[]>([]);

  const currentUser = useCurrentUser();
  const roles = useSelector((state: AppState) => state.roles[project.id] || {});
  const currentUserRole = useUserRole(project.id);

  const permissions = useCurrentUserPermissions();

  const dispatch = useDispatch();

  useEffect(() => {
    // @ts-ignore
    window.amplitude.getInstance().logEvent("PROJECT_SETTINGS_LOADED", {
      user_role: currentUser.role,
      project_name: project.name,
      project_id: project.id,
    });

    setProjectEditable(project);
  }, []);

  useEffect(() => {
    setProjectEditable(project);
  }, [project]);

  useEffect(() => {
    const fetchUsers = async () => {
      const usersSnapshot = await db.collection("users").get();
      const users = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        email: doc.data().email,
        name: doc.data().name,
      }));
      setAllUsers(users);
    };
    fetchUsers();
  }, []);

  const handleSearch = debounce((value: string) => {
    if (value) {
      const filteredUsers = allUsers.filter(
        (user) =>
          user.email.toLowerCase().includes(value.toLowerCase()) ||
          user.name.toLowerCase().includes(value.toLowerCase())
      );
      if (filteredUsers.length === 0) {
        setUserOptions([
          { id: "invite", email: value, name: `Invite ${value}` },
        ]);
      } else {
        setUserOptions(filteredUsers);
      }
    } else {
      setUserOptions([]);
    }
  }, 100);

  async function saveButtonPressed() {
    setConfirmLoading(true);

    try {
      await dispatch(
        actions.project.updateProject(projectEditable, project.id)
      );
      message.success("Changes saved!");
    } catch (error) {
      message.error("Error saving changes");
    }

    setConfirmLoading(false);
  }

  function handleTextChange(
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) {
    var projectTemp = Object.assign({}, projectEditable);
    projectTemp[type] = event.target.value;
    setProjectEditable(projectTemp);
  }

  function addUserToProject() {
    var email = emailToInvite.replace(/\s+/g, "").toLowerCase();

    if (!validateEmail(email)) {
      message.error("Email provided does not look valid");
      return;
    }

    // Check if the user exists
    db.collection("users")
      .where("email", "==", email)
      .get()
      .then(function (querySnapshot) {
        var existsInDb = false;
        var existsAlready = false;

        var tempColab:
          | {
              user: string;
              role: string;
            }
          | undefined = undefined;

        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          project.collaborators.forEach(function (collaborator) {
            if (doc.id === collaborator.user) {
              existsAlready = true;
            }
          });

          tempColab = {
            user: doc.id,
            role: roleToInvite,
          };

          existsInDb = true;
          // Check if user is already there
        });

        if (existsInDb === false) {
          setInviteModalVisible(true);
        }

        // @ts-ignore
        if (existsAlready === true) {
          message.error("User is already a collaborator");
        }

        // @ts-ignore
        if (existsInDb == true && existsAlready == false) {
          db.collection("projects")
            .doc(project.id)
            .set(
              {
                collaborators:
                  firebase.firestore.FieldValue.arrayUnion(tempColab),
                collaborator_ids: firebase.firestore.FieldValue.arrayUnion(
                  tempColab!.user
                ),
              },
              { merge: true }
            )
            .then(function () {
              setEmailtoInvite("");
              //  setRoleToInvite("viewer");
              // @ts-ignore
              window.amplitude
                .getInstance()
                .logEvent("PROJECT_SETTINGS_UPDATED", {
                  action: "collaborator_added",
                });
            })
            .catch(function (error) {});
        }
      })
      .catch(function (error) {});
  }

  function confirmArchive(e) {
    var projectTemp = Object.assign({}, projectEditable);

    projectTemp["archived"] = true;

    dispatch(actions.project.updateProject(projectTemp, project.id));

    navigate("/");
  }

  function confirmDeletion(e) {
    const hide = message.loading(
      "Running deletion, please wait a few seconds",
      0
    );

    if (project.id != null && project.id != "") {
      callCloudFunction("recursiveDelete", { path: `projects/${project.id}` })
        .then((result) => {
          hide();
          message.success("Deletion Complete!");
          navigate("/");
        })
        .catch((error) => {
          hide();
          message.error("Deletion Failed");
        });
    }
  }

  function confirmUnarchive(e) {
    var projectTemp = Object.assign({}, projectEditable);

    projectTemp["archived"] = false;
    // @ts-ignore
    window.amplitude
      .getInstance()
      .logEvent("PROJECT_SETTINGS_UPDATED", { action: "project_unarchived" });

    dispatch(actions.project.updateProject(projectTemp, project.id));
    navigate("/");
  }

  function cancel(e) {}

  function onSlackNotificationChange(hook, field, checked) {
    const hookCopy = _.cloneDeep(hook);

    hookCopy.notifications[field] = checked;

    const projectTemp = _.cloneDeep(projectEditable);

    projectTemp.slackHooks[
      projectTemp.slackHooks.findIndex(
        (item) => item.channel_id == hookCopy.channel_id
      )
    ] = hookCopy;

    setProjectEditable(projectTemp);

    dispatch(actions.project.updateProject(projectTemp, project.id));
  }

  function addonToggled(addon) {
    var projectTemp = _.cloneDeep(projectEditable);

    if (projectTemp.addonsEnabled == null) {
      projectTemp["addonsEnabled"] = [];
    }

    if (projectTemp.addonsEnabled.includes(addon)) {
      projectTemp.addonsEnabled = projectTemp.addonsEnabled.filter(
        (e) => e !== addon
      );
    } else {
      projectTemp.addonsEnabled.push(addon);
    }

    setProjectEditable(projectTemp);
    dispatch(actions.project.updateProject(projectTemp, project.id));
  }

  function removeSlackHook(hook) {
    var projectTemp: Partial<Project> = Object.assign({}, projectEditable);

    const slackHooks = projectTemp.slackHooks.filter(
      (item) => item.channel_id != hook.channel_id
    );

    projectTemp.slackHooks = slackHooks;

    // @ts-ignore
    window.amplitude.getInstance().logEvent("PROJECT_SETTINGS_UPDATED", {
      action: "slack_notifications_removed",
    });
    setProjectEditable(projectTemp);
    dispatch(actions.project.updateProject(projectTemp, project.id));
  }

  // For the invite confirmation
  function handleInviteConfirmation() {
    setInviteLoading(true);

    var newId = id();

    var object = {
      email: emailToInvite.replace(/\s+/g, "").toLowerCase(),
      date: Date(),
      from: currentUser.id,
      role: roleToInvite,
      project: project.id,
    };

    db.collection("invites")
      .doc(newId)
      .set(object)
      .then(function () {
        db.collection("projects")
          .doc(project.id)
          .set(
            {
              invites: firebase.firestore.FieldValue.arrayUnion(newId),
            },
            { merge: true }
          )
          .then(function () {
            setInviteLoading(false);
            setInviteModalVisible(false);
            setEmailtoInvite("");
            setRoleToInvite("viewer");
          })
          .catch(function (error) {
            message.error("Error sending invitation: ", error);
          });
      })
      .catch(function (error) {
        message.error("Error sending invitation: ", error);
      });
  }

  if (projectEditable == null || projectEditable.collaborators == null) {
    return null;
  }

  if (projectEditable.addonsEnabled == null) {
    projectEditable["addonsEnabled"] = [];
  }

  const slackRow = (hook) => {
    return (
      <div
        key={hook.channel_id}
        style={{
          display: "flex",
          fontSize: "17px",
          marginTop: "10px",
          height: "auto",
        }}
      >
        <div
          style={{
            display: "inline-block",
            verticalAlign: "center",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          {hook.channel}
        </div>
        <div
          style={{
            display: "inline-block",
            marginLeft: "auto",
            textAlign: "center",
            marginRight: "50px",
          }}
        >
          <div style={{ display: "inline-block", marginLeft: "25px" }}>
            <div style={{ fontSize: "15px" }}>New Items</div>
            <div>
              <Switch
                size="small"
                style={{ marginLeft: "15px", marginRight: "15px" }}
                checked={hook.notifications.newItems}
                onChange={() =>
                  onSlackNotificationChange(
                    hook,
                    "newItems",
                    !hook.notifications.newItems
                  )
                }
              />
            </div>
          </div>
          <div style={{ display: "inline-block", marginLeft: "25px" }}>
            <div style={{ fontSize: "15px" }}>Status Updates</div>
            <div>
              <Switch
                size="small"
                style={{ marginLeft: "15px", marginRight: "15px" }}
                checked={hook.notifications.statusUpdates}
                onChange={() =>
                  onSlackNotificationChange(
                    hook,
                    "statusUpdates",
                    !hook.notifications.statusUpdates
                  )
                }
              />
            </div>
          </div>
          <div style={{ display: "inline-block", marginLeft: "25px" }}>
            <div style={{ fontSize: "15px" }}>Comments</div>
            <div>
              <Switch
                size="small"
                style={{ marginLeft: "15px", marginRight: "15px" }}
                checked={hook.notifications.comments}
                onChange={() =>
                  onSlackNotificationChange(
                    hook,
                    "comments",
                    !hook.notifications.comments
                  )
                }
              />
            </div>
          </div>
        </div>
        <div style={{ display: "inline-block" }}>
          <Button
            shape="circle"
            icon={<CloseOutlined />}
            style={{ border: "none" }}
            onClick={() => removeSlackHook(hook)}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="section">
      <Modal
        open={inviteModalVisible}
        title="Send Invite?"
        onOk={() => {
          handleInviteConfirmation();
        }}
        onCancel={() => {
          setInviteModalVisible(false);
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setInviteModalVisible(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={inviteLoading}
            onClick={() => {
              handleInviteConfirmation();
            }}
          >
            Send Invitation
          </Button>,
        ]}
      >
        <p>
          It looks like {emailToInvite.replace(/\s+/g, "").toLowerCase()} is not
          on Aloa Manage yet. Would you like to send them an invitation to join?
        </p>
      </Modal>

      <SettingsCard name={"Project Settings"}>
        <div
          style={{
            fontSize: "17px",
            fontWeight: 500,
            fontFamily: "Avenir Next",
          }}
        >
          Project Name
        </div>

        <input
          value={projectEditable.name}
          // @ts-ignore
          style={styles.lineTextBox}
          onChange={(e) => handleTextChange(e, "name")}
        />

        {currentUser.email &&
          (currentUser.email.includes("@aloa.co") ||
            currentUser.email.includes("@aloalabs.com")) && (
            <div style={{ marginTop: "20px" }}>
              <div
                style={{
                  fontSize: "17px",
                  fontWeight: 500,
                  fontFamily: "Avenir Next",
                }}
              >
                Project Tier
              </div>

              <Select
                style={{ width: 250 }}
                value={projectEditable?.project_tier || undefined}
                onChange={(value) => {
                  var projectTemp = Object.assign({}, projectEditable);
                  // If value is None, set it to null
                  if (value === "None") {
                    projectTemp.project_tier = null;
                  } else {
                    projectTemp.project_tier = value;
                  }

                  setProjectEditable(projectTemp);
                }}
                placeholder="Project Tier"
              >
                <Option value="Tier 1">Tier 1</Option>
                <Option value="Tier 2">Tier 2</Option>
                <Option value="Tier 1 Custom">Tier 1 Custom</Option>
                <Option value="Tier 2 Custom">Tier 2 Custom</Option>
                <Option value="None">None</Option>
              </Select>
            </div>
          )}

        <div style={{ marginTop: "20px" }}>
          <div
            style={{
              fontSize: "17px",
              fontWeight: 500,
              fontFamily: "Avenir Next",
            }}
          >
            Performance reports enabled
          </div>

          <Switch
            checked={projectEditable.performance_reports_enabled}
            onChange={(checked) => {
              var projectTemp = Object.assign({}, projectEditable);
              projectTemp.performance_reports_enabled = checked;
              setProjectEditable(projectTemp);
            }}
          />
        </div>

        <div
          style={{
            fontSize: "17px",
            fontWeight: 500,
            fontFamily: "Avenir Next",
            marginTop: "20px",
          }}
        >
          Slack Notifications
        </div>

        <div>
          {projectEditable.slackHooks &&
            projectEditable.slackHooks.map(function (hook) {
              return slackRow(hook);
            })}
        </div>

        <div style={{ marginTop: "20px" }}>
          <a
            href={
              "https://slack.com/oauth/authorize?scope=incoming-webhook&client_id=50580722261.408222886708&state=" +
              project.id
            }
          >
            <img
              alt="Add to Slack"
              height="40"
              width="139"
              src="https://platform.slack-edge.com/img/add_to_slack.png"
              srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
            />
          </a>
        </div>

        <div style={{ marginTop: "20px" }}>
          <div
            style={{
              fontSize: "17px",
              fontWeight: 500,
              fontFamily: "Avenir Next",
            }}
          >
            AI Slack Channel ID
          </div>

          <input
            value={projectEditable.aiSlackChannelId || ""}
            // @ts-ignore
            style={styles.lineTextBox}
            onChange={(e) => {
              const projectTemp = Object.assign({}, projectEditable);
              projectTemp.aiSlackChannelId = e.target.value;
              setProjectEditable(projectTemp);
            }}
            placeholder="Enter Slack channel ID"
          />
        </div>

        <Button
          style={{ marginTop: "20px", float: "right" }}
          onClick={() => saveButtonPressed()}
          loading={confirmLoading}
          type="primary"
        >
          Save
        </Button>
        <div style={{ clear: "right" }}> </div>
      </SettingsCard>

      <SettingsCard name={"Collaborators"}>
        {projectEditable.collaborators.map((collaborator) => (
          <CollaboratorCard collaborator={collaborator} project={project} />
        ))}

        {project.invites &&
          project.invites.map((invite) => (
            <InviteCard invite={invite} project={project} />
          ))}

        {permissions?.project?.collaborators?.edit && (
          <div>
            <div
              style={{
                fontSize: "17px",
                fontWeight: 500,
                fontFamily: "Avenir Next",
                marginTop: "10px",
              }}
            >
              Add Collaborator
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <Select
                showSearch
                value={emailToInvite}
                placeholder="Search for a user"
                style={{ width: 300 }}
                onSearch={handleSearch}
                onChange={(value) => setEmailtoInvite(value)}
                filterOption={false}
              >
                {userOptions.map((user) => (
                  <Option key={user.id} value={user.email}>
                    {user.name} ({user.email})
                  </Option>
                ))}
              </Select>
              <Select
                defaultValue="viewer"
                style={{
                  width: 120,
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
                value={roleToInvite}
                onChange={(e) => setRoleToInvite(e)}
              >
                <Option value="viewer">Viewer</Option>
                <Option value="vendor">Vendor</Option>
                <Option value="client">Client</Option>
                <Option value="admin">Admin</Option>
              </Select>
              <Button onClick={() => addUserToProject()} type="primary">
                Add User
              </Button>
            </div>
          </div>
        )}
      </SettingsCard>

      {permissions?.project?.settings?.edit && (
        <SettingsCard name={"Customization"}>
          {components &&
            Object.keys(components).map(function (key) {
              var sectionConfig = components[key];

              if (sectionConfig) {
                return (
                  <Section
                    project={project}
                    addonToggled={addonToggled}
                    sectionConfig={sectionConfig}
                    existingKeys={Object.keys(components)}
                  />
                );
              }
            })}

          <div
            onClick={() => {
              navigate(`/p/${project.id}/editor/section/create`);
            }}
            className="create-section-button"
          >
            Create Section
          </div>
        </SettingsCard>
      )}

      {permissions?.project?.settings?.delete && !projectEditable.archived && (
        <Popconfirm
          title="Project will appear in your archives, do you wish to proceed?"
          onConfirm={(e) => {
            confirmArchive(e);
          }}
          onCancel={(e) => {
            cancel(e);
          }}
          okText="I understand"
          cancelText="Nevermind"
        >
          <Button
            style={{
              marginTop: "20px",
              marginBottom: "100px",
              float: "left",
            }}
            // @ts-ignore
            type="danger"
          >
            Archive Project
          </Button>
        </Popconfirm>
      )}

      {permissions?.project?.settings?.delete && projectEditable.archived && (
        <Popconfirm
          title="Project will appear in your active projects, do you wish to proceed?"
          onConfirm={(e) => {
            confirmUnarchive(e);
          }}
          onCancel={(e) => {
            cancel(e);
          }}
          okText="I understand"
          cancelText="Nevermind"
        >
          <Button
            style={{
              marginTop: "20px",
              marginBottom: "100px",
              float: "left",
            }}
            // @ts-ignore
            type="danger"
          >
            Un-Archive Project
          </Button>
        </Popconfirm>
      )}

      {permissions?.project?.settings?.delete &&
        currentUser.acc_type == "2" && (
          <Popconfirm
            title="Project data cannot be recovered, do you wish to proceed?"
            onConfirm={(e) => {
              confirmDeletion(e);
            }}
            onCancel={(e) => {
              cancel(e);
            }}
            okText="I understand"
            cancelText="Nevermind"
          >
            <Button
              style={{
                marginTop: "20px",
                marginBottom: "100px",
                float: "left",
                marginLeft: "10px",
              }}
              // @ts-ignore
              type="danger"
            >
              Delete Project
            </Button>
          </Popconfirm>
        )}

      {permissions?.project?.settings?.delete &&
        currentUser.acc_type == "2" && (
          <div>
            <Button
              onClick={() => {
                setDuplicationModalVisible(true);
              }}
              style={{
                marginTop: "20px",
                marginBottom: "100px",
                float: "left",
                marginLeft: "10px",
              }}
            >
              Duplicate Project
            </Button>
            <DuplicationModal
              projectId={project.id}
              visible={duplicationModalVisible}
              setVisible={setDuplicationModalVisible}
            />
          </div>
        )}

      <div style={{ height: 150 }}> </div>
    </div>
  );
}

export default ProjectSettings;
