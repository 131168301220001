import React from "react";
import AloaLogo from "../../images/app_icon.png";
import { TextBox } from "./TextBox";
import { useCurrentUser } from "../../hooks";
import { ModelId } from "./config";

export function NewConversation({
  startNewConversation,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  updateConversationDateRange,
  model,
  setModel
}: {
  startNewConversation: (message: string) => void;
  startDate?: Date;
  setStartDate?: (date: Date) => void;
  endDate?: Date;
  setEndDate?: (date: Date) => void;
  updateConversationDateRange?: (start: Date, end: Date) => void;
  model: ModelId;
  setModel: (model: ModelId) => void;
}) {
  const currentUser = useCurrentUser();

  return (
    <div className="flex-1 flex flex-col bg-white border-neutral-200 drop-shadow-md relative items-center justify-center rounded-xl">
      <div className="flex items-center gap-4 w-4/5 px-4 mb-6">
        <img
          src={AloaLogo}
          alt="aloa logo"
          className="size-12 object-contain"
        />
        <div>
          <div className="font-bold text-3xl">
            Hi, {currentUser?.name?.split(" ")[0] || "there"}!
          </div>
          <div className="font-light text-sm">How can I help?</div>
        </div>
      </div>
      <div className="w-4/5 pb-4">
        <TextBox
          isLoading={false}
          onSubmit={startNewConversation}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          updateConversationDateRange={updateConversationDateRange}
          model={model}
          setModel={setModel}
        />
      </div>

      <div className="w-4/5 px-4 flex flex-col">
        {recentUpdatesActivity.map((activity, index) => (
          <div className="group cursor-pointer" key={index}>
            <div className="flex items-center justify-between gap-2 text-sm text-neutral-600 py-4 px-1 rounded-lg group-hover:bg-neutral-200/40">
              <div>{activity?.title}</div>
            </div>
            {recentUpdatesActivity?.length - 1 != index && (
              <div className="w-full bg-neutral-200 p-[0.5px] flex group-hover:bg-transparent" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

const recentUpdatesActivity = [
  {
    title:
      "Current Sprint Status: Assistant Improvements & Deeplink Integration",
    // time: "30m",
  },
  {
    title:
      "Latest Team Discussion: Frontend Support for Slack/Manage Deeplinking",
    // time: "2d",
  },
  {
    title:
      "Recent Technical Updates: 3 fixes for deeplink formatting and data retrieval",
    // time: "1w",
  },
  {
    title: "Today's Development Focus: Frontend Improvements",
    // time: "2w",
  },
];
